import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../../components/layout';

import UserPasswordReset from './../../../../components/s4n/AccountManagement/PasswordReset';

const User = () => {
  return (
    <Layout className={`user-password-reset__page`}>
       
        <UserPasswordReset />

    </Layout>
  )
}

export default User;